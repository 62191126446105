<template>
  <div>
    <px-toolbar title="New Scope" />
    <!--begin::Container-->
    <div class="container-xxl pb-10">
      <!--begin::Content-->
      <div class="content flex-row-fluid">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Body-->
          <div class="card-body position-relative">
            <px-loader v-if="isLoading" :is-full-window="false" bg="blue" />
            <!--begin::Form-->
            <form class="form mx-auto mw-800px w-100 py-15" novalidate="novalidate">
              <!--begin::Group-->
              <div class="mb-5">
                <!--begin::Input group-->
                <div class="row align-items-center mb-10">
                  <label class="col-lg-3 col-xl-2 form-label fw-semi-bold mb-4 mb-lg-0">Type</label>
                  <div class="col-lg-9 col-xl-10 fv-row fv-plugins-icon-container">
                    <div class="btn-group w-100">
                      <!--begin::Radio-->
                      <label
                        v-for="type in types"
                        :key="type"
                        class="btn btn-sm btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary"
                        :class="activeRadioBtn(type, form.state)"
                      >
                        <!--begin::Input-->
                        <input type="radio" name="proposal-type" class="btn-check" :value="type" v-model="form.state" />
                        <!--end::Input-->
                        {{ type }}
                      </label>
                      <!--end::Radio-->
                    </div>
                  </div>
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div v-if="form.state === 'Proposal'" class="row align-items-center mb-10">
                  <label class="col-lg-3 col-xl-2 form-label fw-semi-bold mb-4 mb-lg-0">Scope</label>
                  <div class="col-lg-9 col-xl-10 fv-row fv-plugins-icon-container">
                    <div class="btn-group w-100" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
                      <!--begin::Radio-->
                      <label
                        v-for="type in scopes"
                        :key="type"
                        class="btn btn-sm btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary"
                        :class="activeRadioBtn(type, form.scope_type) + validateModel($v.form.scope_type.$error)"
                      >
                        <!--begin::Input-->
                        <input
                          v-model="form.scope_type"
                          :value="type"
                          type="radio"
                          name="proposal-type"
                          class="btn-check"
                        />
                        <!--end::Input-->
                        {{ type }}
                      </label>
                      <!--end::Radio-->
                    </div>
                  </div>
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div class="row align-items-center mb-10">
                  <label class="col-lg-3 col-xl-2 form-label fw-semi-bold mb-4 mb-lg-0">Project</label>

                  <div class="col-lg-9 col-xl-10 d-flex align-items-center">
                    <div class="position-relative flex-grow-1">
                      <px-select
                        :state="$v.form.subject_id.$error"
                        v-model="form.subject_id"
                        placeholder="Select project"
                        track-by="id"
                        :items="filteredProjectsNames"
                        :disabled="!!existingProposalId"
                        :text-by="['attributes.address', ' - ', 'attributes.first_name', ' ', 'attributes.last_name']"
                        select2-id="select2_clients"
                        @input="onProjectSelect"
                      />
                    </div>

                    <a
                      v-if="form.state === 'Proposal'"
                      class="text-nowrap ms-4"
                      href="javascript:void(0);"
                      @click.prevent="openProjectModal"
                    >
                      <span class="me-1">+</span>
                      New Project
                    </a>
                  </div>
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div v-if="!hasExtendedRoles" class="row align-items-center mb-10">
                  <label class="col-lg-3 col-xl-2 form-label fw-semi-bold mb-4 mb-lg-0">Manager</label>

                  <div class="col-lg-9 col-xl-10">
                    <input :value="managerName" type="text" class="form-control form-control-solid" readonly />
                  </div>
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div v-if="form.state === 'Proposal'" class="row align-items-center mb-10">
                  <label class="col-lg-3 col-xl-2 form-label fw-semi-bold mb-4 mb-lg-0">Designer</label>

                  <div class="col-lg-9 col-xl-10">
                    <px-select
                      v-if="designers.data"
                      v-model="usersVal"
                      :clear="this.hasExtendedRoles"
                      placeholder="Select designer(s)"
                      :items="designers.data"
                      :locked-items="usersLocked"
                      multiple
                      track-by="id"
                      :text-by="['attributes.first_name', ' ', 'attributes.last_name']"
                      select2-id="select2_user_ids"
                    />
                  </div>
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div v-if="form.state === 'Change Order'" class="row align-items-center mb-10">
                  <label class="col-lg-3 col-xl-2 form-label fw-semi-bold mb-4 mb-lg-0">Contract</label>

                  <div class="col-lg-9 col-xl-10">
                    <div class="position-relative flex-grow-1">
                      <px-select
                        :state="$v.form.contract.$error"
                        v-model="form.contract"
                        placeholder="Select contract"
                        track-by="attributes.number"
                        :items="proposalsNames"
                        text-by="attributes.number"
                        :minimum-results-for-search="Infinity"
                        select2-id="select2_contracts"
                      />
                    </div>
                  </div>
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div
                  v-if="form.state === 'Proposal' && proposalScopeTemplateNames && form.scope_type"
                  class="row align-items-center mb-10"
                >
                  <label class="col-lg-3 col-xl-2 form-label fw-semi-bold mb-4 mb-lg-0">Template</label>

                  <div class="col-lg-9 col-xl-10">
                    <px-select
                      v-model="form.proposal_scope_template_id"
                      placeholder="Select template"
                      track-by="id"
                      :items="filteredProposalScopeTemplateNames"
                      text-by="attributes.name"
                      :clear="true"
                      select2-id="select2_templates"
                    />
                  </div>
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Group-->

              <!--begin::Actions-->
              <div class="text-end">
                <px-btn
                  color="success"
                  extended-classes="me-2"
                  :in-process="saveAndExitInProcess"
                  :disabled="continueInProcess || saveAndExitInProcess"
                  @click.native="onSubmit"
                >
                  Save & Exit
                </px-btn>
                <px-btn
                  :in-process="continueInProcess"
                  :disabled="saveAndExitInProcess || continueInProcess"
                  @click.native="goToWizard"
                >
                  Continue
                </px-btn>
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end: Card Body-->
        </div>
        <!--end::Card-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Container-->

    <!-- new project begin -->
    <px-new-modal :is-visible="isProjectModalOpened" :close-modal="closeProjectModal">
      <template #title>
        <h3>New Project</h3>
      </template>
      <template #content>
        <pxm-project @onClose="closeProjectModal" />
      </template>
    </px-new-modal>
    <!-- new project end -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ProposalMixin from '@/mixins/ProposalMixin';

import PxmProject from '@/modals/PxmProject';

export default {
  mixins: [ProposalMixin],
  components: {
    PxmProject,
  },
  data() {
    return {
      isLoading: false,
      isProjectModalOpened: false,
      existingProposalId: null,
      newAddedProposalScopeType: null,
      proposalType: 'Proposal',
      usersVal: [],
      usersLocked: [],

      saveAndExitInProcess: false,
      continueInProcess: false,

      managerName: null,
    };
  },
  computed: {
    ...mapGetters({
      message: 'proposals/message',
      proposalScopeTemplates: 'proposals/proposalScopeTemplates',
      proposalScopeTemplateNames: 'proposals/proposalScopeTemplateNames',
      projectsNames: 'projects/names',
      proposalsNames: 'proposals/names',
      designers: 'users/designers',
      currentUser: 'auth/user',
    }),
    hasExtendedRoles() {
      const groups = this.currentUser.attributes.groups.data;

      return (
        !!groups.find((el) => el.attributes.name === 'Admin') ||
        !!groups.find((el) => el.attributes.name === 'Master') ||
        !!groups.find((el) => el.attributes.name === 'Manager')
      );
    },
    // designers() {
    //   return this.users?.data?.filter((el) =>
    //     el.attributes.groups.data.find((elj) => elj.attributes.name === 'Designer')
    //   );
    // },
    filteredProjectsNames() {
      return this.form.state === 'Change Order'
        ? [...this.projectsNames.filter((el) => el.attributes.status === 'Signed')]
        : [...this.projectsNames];
    },
    fullName(fn, ln) {
      return `${fn} ${ln}`;
    },
    filteredProposalScopeTemplateNames() {
      return this.proposalScopeTemplateNames
        .filter((el) => el.attributes.scope_type === this.form.scope_type)
        .sort((a, b) => {
          if (a.attributes.name.toLowerCase() < b.attributes.name.toLowerCase()) {
            return -1;
          }
          if (a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase()) {
            return 1;
          }

          return 0;
        });
    },
  },
  async mounted() {
    await this.init();
    await this.designersIndex({ per: 10000 });
  },
  methods: {
    ...mapActions({
      single: 'proposals/single',
      create: 'proposals/create',
      update: 'proposals/update',
      actionProjectsNames: 'projects/names',
      actionProposalsNames: 'proposals/names',
      actionProposalScopeTemplateNames: 'proposals/proposalScopeTemplateNames',
      designersIndex: 'users/designersIndex',
    }),
    async init() {
      this.isLoading = true;

      if (this.hasExtendedRoles) {
        await this.actionProjectsNames({ archive: false, manager_id: this.currentUser.id });
      } else {
        await this.actionProjectsNames({ archive: false });
      }

      await this.actionProposalScopeTemplateNames({ fields: 'name,scope_type' });

      if (this.form.subject_id) {
        await this.actionProposalsNames({ subject_id: this.form.subject_id, status: 'Signed' });
      }

      this.existingProposalId = this.$route.query.id;
      if (this.$route.params.proposalType) {
        this.proposalType = this.$route.params.proposalType;
      }
      this.form.state = this.proposalType;

      if (this.existingProposalId) {
        const fieldsArr = ['scope_type', 'subject', 'users'];
        const currentProposal = await this.single({
          id: this.$route.query.id,
          params: {
            fields: this.arrayToString(fieldsArr),
          },
        });
        const currentProposalAttributes = currentProposal?.data?.data?.attributes;
        const newUsers = new Set();
        for (let val of currentProposalAttributes.users.data) {
          newUsers.add(val.id);
        }

        this.form.scope_type = currentProposalAttributes.scope_type;
        this.usersVal = [...newUsers];
        this.form.subject_id = currentProposalAttributes.subject.data.id;
      }

      if (!this.existingProposalId && !this.hasExtendedRoles) {
        this.usersVal = [this.currentUser.id];
        this.usersLocked = [this.currentUser.id];
      }

      this.isLoading = false;
    },
    async onProjectSelect() {
      const selectedProject = this.filteredProjectsNames.find((el) => el.id === this.form.subject_id);

      this.managerName = selectedProject?.attributes?.manager_username || null;

      await this.actionProposalsNames({ subject_id: this.form.subject_id, status: 'Signed' });
    },
    async onSubmit() {
      this.saveAndExitInProcess = true;

      let changeOrderScopeType = '';

      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      }

      if (this.form.state === 'Change Order') {
        const selectedContract = this.proposalsNames.find((el) => el.attributes.number === this.form.contract);

        changeOrderScopeType = selectedContract?.attributes?.scope_type || '';
      }

      const proposalData = {
        id: this.form.subject_id,
        user_ids: this.usersVal,
        // Not required
        scope_type: this.form.state === 'Proposal' ? this.form.scope_type : changeOrderScopeType,
        state: this.form.state,
        subject_id: this.form.subject_id,
      };

      if (this.existingProposalId) proposalData.id = this.existingProposalId;
      delete proposalData.users;

      if (!this.existingProposalId) {
        await this.create({
          id: proposalData.id,
          proposal_scope: proposalData,
        });

        this.saveAndExitInProcess = false;

        await this.$router.push({ name: 'single-project', params: { id: proposalData.subject_id } });
      }

      if (this.existingProposalId) {
        await this.update({
          id: proposalData.id,
          proposal_scope: proposalData,
        });

        this.saveAndExitInProcess = false;

        await this.$router.push({ name: 'single-project', params: { id: this.form.subject_id } });
      }
    },
    async goToWizard() {
      this.continueInProcess = true;

      let changeOrderScopeType = '';

      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        this.continueInProcess = false;

        return false;
      }

      if (this.form.state === 'Change Order') {
        const selectedContract = this.proposalsNames.find((el) => el.attributes.number === this.form.contract);

        changeOrderScopeType = selectedContract?.attributes?.scope_type || '';
      }

      const proposalData = {
        id: this.form.subject_id,
        user_ids: this.usersVal,
        // Not required
        scope_type: this.form.state === 'Proposal' ? this.form.scope_type : changeOrderScopeType,
        state: this.form.state,
        subject_id: this.form.subject_id,
      };
      if (this.form.proposal_scope_template_id)
        proposalData.proposal_scope_template_id = this.form.proposal_scope_template_id;

      if (this.existingProposalId) {
        await this.update({
          id: this.existingProposalId,
          proposal_scope: proposalData,
        });

        await this.$router.push({
          name: this.form.state === 'Proposal' ? 'proposal-wizard.introduction' : 'proposal-wizard.pricing',
          params: {
            id: this.existingProposalId,
          },
        });

        this.continueInProcess = false;
      } else {
        const createdItem = await this.create({
          id: proposalData.id,
          proposal_scope: proposalData,
        });

        await this.$router.push({
          name: this.form.state === 'Proposal' ? 'proposal-wizard.introduction' : 'proposal-wizard.pricing',
          params: {
            id: createdItem.id,
          },
        });

        this.continueInProcess = false;
      }
    },
    openProjectModal() {
      this.isProjectModalOpened = true;
    },
    closeProjectModal(data) {
      if (data) {
        this.form.subject_id = data.id;
        this.onProjectSelect();
      }

      this.isProjectModalOpened = false;
    },
  },
};
</script>
