import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      form: {
        scope_type: '',
        subject_id: '',
        state: 'Proposal',
        contract: '',
        proposal_scope_template_id: '',
      },
      showModal: false,
    };
  },
  validations() {
    return {
      form: {
        scope_type: { required: requiredIf((prop) => prop.state === 'Proposal') },
        subject_id: { required },
        state: { required },
        contract: { required: requiredIf((prop) => prop.state === 'Change Order') },
      },
    };
  },
  computed: {
    scopes() {
      return ['Landscaping', 'Swimming Pool', 'Woodworking'];
    },
    types() {
      return ['Proposal', 'Change Order'];
    },
  },
  methods: {
    defaultValues() {
      this.form = {
        scope_type: '',
        subject_id: '',
        state: 'Proposal',
        contract: '',
        proposal_scope_template_id: '',
      };
    },
  },
};
